import { wxPay } from '@/utils/wx-promise';
import { goWxPay } from '@/service/enroll-activity/index';
import { getToken } from "@/utils/localStorage.js";


export default{
    data(){
        return{
            loading: false,
            name: '',
            identity: '',
            tourismPackages: '',
            amount: 0,
        }
    },
    created(){
        let { name, identity, tourismPackages, amount, code } = this.$route.query;
        this.name = name
        this.identity = identity
        this.tourismPackages = tourismPackages
        this.amount = amount
        this.code = code
    },
    methods:{
        onBack(){
            let { from } = this.$route.query;
            if(['add-enroll'].includes(from)){ // 从列表页面
                this.$router.go(-1);
            }else {
                this.$router.go(-2);
            }
        },

        // 支付
        async onWxPay(){
            let { code, amount, tourismPackages } = this;
            this.loading = true
            const params = {
                payType: 'WECHAT',
                businessCode: code,
                businessTableName: 'ACT_SIGNUP_AGENT_RECORD',
                token: getToken(),
                amount: parseFloat(amount),
                message: tourismPackages
            }
            
            try {
                const res = await goWxPay(params)
                this.loading = false

                // 调用内置微信支付方法  发起支付
                const payParams = {
                    appId: res.appId,
                    timeStamp: res.timeStamp,
                    nonceStr: res.nonceStr,
                    package: res.packageValue,
                    signType: res.signType,
                    paySign: res.paySign
                }
                wxPay(payParams).then(wxRes =>{
                    this.$router.push({
                        name: 'retail-success-info',
                        query: { from: 'wxPay' }
                    })
                })
            } catch (error) {
                this.loading = false
            }
        }
    }
}